import React from "react";
import "./App.css";


const Home = () => {
    return (
        <div>Home Stuff</div>
    )
};


export default Home;