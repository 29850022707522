import React from "react";
import "./App.css";



const AboutMe = () => {
    return (
        <div>About Me Stuff</div>
    )
};


export default AboutMe;